import { React, useState } from "react";
import { Link } from "react-router-dom";
import pics from "../assets/photojoh.jpg";
import "../styles/about.css";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faJs,
  faHtml5,
  faSass,
  faFigma,
  faGithub,
  faWordpress,
} from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as ReduxLogo } from "../assets/redux.svg";
import { ReactComponent as Typescript } from "../assets/typescript.svg";
import { ReactComponent as Diploma } from "../assets/diploma.svg";
import { ReactComponent as France } from "../assets/france.svg";
import { ReactComponent as Usa } from "../assets/usa.svg";
import { ReactComponent as Spain } from "../assets/spain.svg";
import { ReactComponent as Elementor } from "../assets/elementor.svg";
import { ReactComponent as Certificate } from "../assets/certificate.svg";
import Surf from "../assets/Hobbies/7.png";
import Moto from "../assets/Hobbies/1.png";
import Benevolat from "../assets/Hobbies/2.png";
import Montage from "../assets/Hobbies/3.png";
import Art from "../assets/Hobbies/4.png";
import Roller from "../assets/Hobbies/5.png";
import Yoga from "../assets/Hobbies/6.png";
import Dive from "../assets/Hobbies/8.png";


function About() {

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible); 
    console.log('ça marche')
  };

  const handleScroll = () => {
    const element = document.getElementById("hobbies_style");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollXp = () => {
    const place = document.getElementById("xp")
    if (place) {
      place.scrollIntoView({ behavior: "smooth" });
    }
  };

  const actions = () => {
    toggleVisibility();
    setTimeout(() => {
      handleScrollXp();
    }, 100);

  };

  const data = [
    {
      subject: "Adaptabilité",
      "en %": 100,
      fullMark: 100,
    },
    {
      subject: "Relationnel",
      "en %": 80,
      fullMark: 100,
    },
    {
      subject: "Force de proposition",
      "en %": 80,
      fullMark: 100,
    },
    {
      subject: "Gestion de projet",
      "en %": 80,
      fullMark: 100,
    },
    {
      subject: "Motivation",
      "en %": 100,
      fullMark: 100,
    },
    {
      subject: "Conscience pro.",
      "en %": 90,
      fullMark: 100,
    },
  ];

  const Skildivcon = ({ icon, name }) => (
    <div className="icon i-skills">
      {icon}
      <div className="langage-txt">{name}</div>
    </div>
  );

  return (
    <div className="basic">
      <div className="about_me_card">
        <div className="nav_space_ab">
          <div className="about_title">About Me</div>
          <div className="nav_about">
            <Link to="/" className="nav">
              Home
            </Link>
            <div className="nav otherXp" 
            onClick={actions}>
            Other Experiences</div>
            <div className="nav" onClick={handleScroll} >Hobbies</div>
          </div>
        </div>

        <div className="about_space">
          <div className="pics_space">
            <div className="photo_space">
              <img src={pics} alt="photo_profil" className="pics" />
            </div>
            <div className="txt_photo">
              <span className="presentation">JOHANNA FLEURY</span>
              <span className="job"> Développeuse Front-End</span>
            </div>

            <div className="diploma_space_flex">
              <div className="diploma_space">
                <div className="dpl">
                  <Certificate width={40} height={40} />
                </div>
                <span className="field">
                  Développeuse Front-End / Conception d'application React
                </span>
              </div>

              <div className="diploma">
                <Diploma width={155} height={155} />
                <span className="licence">
                  Diplômes Bac+3 (Double Licences)
                </span>
              </div>

              <div className="diploma_space">
                <div className="dpl">
                  <Certificate width={40} height={40} />
                </div>
                <span className="field">
                  Marketing & Management de l'Evenementiel
                </span>
              </div>
            </div>
          </div>

          <div className="about_txt">
            <div className="column">
              <div className="paragraph">
                Depuis toujours, je suis fascinée par le design, les tendances
                et le marketing, des domaines qui ont constamment éveillés ma
                curiosité.
                <p>
                  Pour moi, une application ou un site internet doit avoir deux
                  atouts principaux : un certain pouvoir de séduction et une
                  capacité à inspirer confiance.
                </p>
                <p>
                  Lorsque je travaille sur un projet, j'aspire à avoir une
                  approche tourner sur l'accessibilité et la clarté de mon code.
                </p>
                L’utilisateur doit pouvoir trouver rapidement ce qu'il cherche
                ou avoir envie de découvrir davantage le site/app, cette
                navigation doit se faire de manière intuitive et susciter son
                intérêt.
              </div>
              <div className="langage">
                <div className="flag_space">
                  <Usa width={40} height={40} />
                  <span className="level">B2</span>
                </div>
                <div className="flag_space">
                  <France width={40} height={40} />
                  <span className="level">C2</span>
                </div>
                <div className="flag_space">
                  <Spain width={40} height={40} />
                  <span className="level">A2</span>
                </div>
              </div>
            </div>
            <div className="about_sub">
              <div className="hardSkills">
                Hard Skills
                <div className="font_icon">
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faHtml5} />}
                    name="HTML5"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faSass} />}
                    name="SASS"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faJs} />}
                    name="JavaScript"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faReact} />}
                    name="React"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faFigma} />}
                    name="Figma"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faGithub} />}
                    name="GitHub"
                  />
                  <Skildivcon
                    icon={<FontAwesomeIcon icon={faWordpress} />}
                    name="WordPress"
                  />
                  <Skildivcon
                    icon={<Elementor width={30} height={30} />}
                    name="Elementor"
                  />
                  <Skildivcon
                    icon={<ReduxLogo width={30} height={30} />}
                    name="Redux"
                  />
                  <Skildivcon
                    icon={<Typescript width={30} height={30} />}
                    name="TypeScript"
                  />
                </div>
              </div>
              <div className="softSkills">
                <span className="SK_title">Soft Skills</span>

                <div className="reCharts">
                  <ResponsiveContainer width="100%" height={250}>
                  <RadarChart
                    outerRadius={90}
                    width={430}
                    height={250}
                    data={data}
                  >
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" fontSize={14} />
                    <PolarRadiusAxis
                      angle={30}
                      domain={[0, 100]}
                      fontSize={15}
                    />
                    <Radar
                      dataKey="en %"
                      stroke="#4a5c3e"
                      fill="#3b4c2e"
                      fillOpacity={0.7}
                    />
                    <Legend />
                  </RadarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="hobbies_style">
          <div className="sub-title">Interests</div>
          <div className="interest-space">
            <div className="bubble">
                <img src={Roller} alt="Speed Roller" className="hobbies_img" />
                <div className="text">Roller de Vitesse</div>
              </div>
              <div className="bubble">
              <img src={Surf} alt="surf" className="hobbies_img" />
              <div className="text">Surf, Kite, Wing</div>
            </div>
            <div className="bubble">
              <img src={Yoga} alt="yoga" className="hobbies_img" />
              <div className="text">Yoga & Randonnée</div>
            </div>
            <div className="bubble">
              <img src={Moto} alt="moto" className="hobbies_img" />
              <div className="text">Moto 125 & Voyages</div>
            </div>
            <div className="bubble">
              <img src={Dive} alt="dive" className="hobbies_img" />
              <div className="text">Plongée</div>
            </div>
            <div className="bubble">
              <img src={Montage} alt="montage" className="hobbies_img" />
              <div className="text">Montage Vidéo</div>
            </div>
            <div className="bubble">
              <img src={Benevolat} alt="benevolat" className="hobbies_img" />
              <div className="text"> Web Bénévolat</div>
            </div>
            <div className="bubble">
              <img src={Art} alt="art" className="hobbies_img" />
              <div className="text">Art Contemporain</div>
            </div>
          </div>
        </div>
        <div className="about_title_space ">
          <div className="parag_space">
            <div className="about_style">
              <div className="xp">Experiences</div>
              <div className="xp_style">
                <div className="xp_card">
                  <div className="year">2024</div>
                  <div>
                    (en cours) Refonte du site "Toulouse en Transition" géré via
                    Wordpress/Elementor
                  </div>
                  <a href="https://toulouse.entransition.fr/" className="link">
                    https://toulouse.entransition.fr/
                  </a>
                  <div className="problematique">
                    <strong>Problématique :</strong> Conflit des widgets
                    entraînant des erreurs ce qui affaiblissait la sécurité du site
                    internet, site vieillissant.
                  </div>
                </div>

                <div className="xp_card">
                  <div className="year">2023-2024</div>
                  <div>
                    Création du site internet "Chez Fanfan" pour un restaurant à
                    Cornebarrieu
                  </div>
                  <a href="https://chezfanfan.fr/" className="link">
                    https://chezfanfan.fr/
                  </a>
                  <div className="problematique">
                    <strong>Problématique :</strong> Site internet from scratch
                    et mise en place d'un SEO efficace.
                  </div>
                </div>

                <div className="xp_card">
                  <div className="year">2021-2024</div>
                  <div>Etude de 14 projets diplômants</div>
                  <div className="problematique">
                    <strong>Problématique :</strong> Utilisation de différents
                    langages et librairies (React, Javascript, Factory, Call
                    API, Back-End,...).
                  </div>
                </div>
              </div>

              <div className="" style={{ display: isVisible ? 'inline-block' : 'none' }}>

                <div id="xp">Other Experiences</div>
                <div className="xp_card">
                  <div className="year">2021-2024</div>
                  <div className="">Toulouse, Occitanie</div>
                  <div className="job_xp">
                    Conseillère en énergie renouvelable - ILEK
                  </div>
                  <div className="xp_pg">
                    J'ai occupé ce poste en parallèle de ma reconversion
                    professionnelle. Il fallait pouvoir maitrîser tous les
                    aspects de vie du contrat (souscription, activation,
                    annulation, facturation, recouvrement, résiliation). Après
                    quelques mois, j'ai évolué vers un poste d'agent de
                    recouvrement, où j'ai consacré 45% de mon temps à cette
                    activité. En l'espace de deux ans, j'ai également été promue
                    au rôle de référente des connaissances métiers (Problem
                    Solver). Ce poste polyvalent m'a permis de travailler dans
                    des conditions variées, y compris durant la crise
                    énergétique, où l'adaptabilité était essentielle.
                  </div>
                  <div className="problematique">
                    <strong>Points Forts :</strong> Travailler sous pression,
                    savoir adapté sa communication à tout types d'auditoire
                    (reformulation), remontée des bugs du site internet et back
                    office.
                  </div>
                </div>
                <div className="xp_card">
                  <div className="year">2020-2021</div>
                  <div className="year">Pau, Pyrénées-Atlantique</div>
                  <div className="job_xp">
                    Wedding Designer (Auto-Entrepreneuse) - Le Secret du Détail{" "}
                  </div>
                  <div className="xp_pg">
                    Avant le confinement, j'ai créée mon auto-entreprise dans la
                    décoration évèmentielle (mariage zéro-déchêt). Le
                    confinement arrivé, j'ai orienté mon activité sur de la
                    vente de box zéro déchêts en ligne (création d'un site
                    internent / SEO / Marketing Digital)
                  </div>
                  <div className="problematique">
                    <strong>Points Forts :</strong> Création, Adaptabilité,
                    Travailler dans un contexte de crise, Création d'un site
                    internet sous WordPress / Elementor / Shopify
                  </div>
                </div>
                <div className="xp_card">
                  <div className="year">2014-2015</div>
                  <div className="year">Papeete, Tahiti</div>
                  <div className="job_xp">
                    Co-Chargée de Projet Evènementiels - SYNERGENCE{" "}
                  </div>
                  <div className="xp_pg">
                    Gestion des différents évènements (sportifs, soirées
                    évènementielles,...) à Tahiti.
                  </div>
                  <div className="problematique">
                    <strong>Points Forts :</strong> Gestion d'équipes,
                    Organisation, Planification, Recrutement, Anticipation,
                    Réaction.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
