import '../styles/modale.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Modale({item, closeModal}){
    if (!item) return null;

    const handleClick = () => {
       console.log(item.link)
       window.open(item.link, "_blank");
    }

    return(
        <div>
            <div className="modale-space">
                <div className="modale-infos">
                    <div className='modale-cross'>
                        <FontAwesomeIcon icon={faTimes} 
                        className='cross' 
                        onClick={closeModal}/> 
                    </div>
                    <h2 className='title-item'>{item.description}</h2>
                    <div className='space-item'>
                        <div className='prg-item txt' >{item.paragraphe}</div>
                            <div className='flex-pics'>
                                <div className='size-pics'>
                                    {item.photos && item.photos.map((pics, index )=> (
                                        <img 
                                        key={index}
                                        src={pics} 
                                        alt={`pics-${index}`} 
                                        className='pics-item '/>
                                    ))}
                    
                                </div>
                            </div>
                        <div className='bnf-item-txt'>{item.discover}</div> 
                            {item.link && 
                            <div className='link-item'>
                                <button className=' btn_modale'onClick={handleClick}>Visiter le site</button>
                            </div> }
                        <div className='bnf-item txt '>{item.benefit}</div>
                        <FontAwesomeIcon icon={item.icon} className=''/>
                        <div className='tny-item txt'>{item.technology}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modale;