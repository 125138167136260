import "../styles/project.css"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { faCodeFork } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';


function Projects(){

    return(
        <div className="space">
            <div className='icons_space'>
                <div>
                    <FontAwesomeIcon icon={faLaptopCode} className="icon_ft" />
                    <div className="icon_title">Front-End</div>
                </div>
                <div>
                    <Link to="/AboutMe" aria-label="Learn more about me">
                    <FontAwesomeIcon icon={faCodeFork} className="icon_ft"/>
                    </Link>
                    <div className="icon_title">Skills</div>
                    
                </div>
                <div>
                    <FontAwesomeIcon icon={faFingerprint} className="icon_ft"/>
                    <div className="icon_title">Projects</div>
                </div>
                {/* <div>
                    <FontAwesomeIcon icon={faSquarePollHorizontal} className="icon_ft"/>
                    <div className="icon_title">Hobbies</div>
                </div> */}
                <div>
                    <a href="https://www.linkedin.com/in/johanna-fleury-dev6485" aria-label="Learn more about my skills">
                    <FontAwesomeIcon icon={faSitemap} className="icon_ft"/>
                    </a>
                    <div className="icon_title">LinKeDin</div>
                </div>
                <div>
                <Link to="/Contact" aria-label="want to get in touch">
                    <FontAwesomeIcon icon={faAddressCard} className="icon_ft" />
                </Link>
                <div className="icon_title">Contact</div>
                </div>
            </div>
            <div className="ttl_space">
                <div className="title">Projects</div>
            </div>
           
        </div>
    )
}

export default Projects