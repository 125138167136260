import '../styles/footer.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Footer(){
    return(
        <div className="footer_space">
            <div className='creator'>created by Johanna</div>
            <div className="footer_menu">
                {/* <div className='link_pjt'>Projects</div> */}
                <Link to="/AboutMe">About Me</Link>
                <Link to="/Contact">Contact</Link>
                <Link to="/">Home</Link>
            </div>
        </div>
    )
}

export default Footer