import React from 'react';
import '../styles/header.css';
import { Link } from 'react-router-dom';

function Header() {

    const handleScrollPr = () => {
        const element = document.querySelector("prj");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          console.log('test')
        }
      };

    return (
        <div className="header_space">
            {/* <img 
               src=""
                alt="background img"
                className="background_image"
            /> */}

            <div className='title_space'>
                <span className='title_main'>Découvrez mes projets</span>
                <span className='under_title'>Vous avez un projet ambitieux ? 
                Je suis une développeuse passionnée avec une expertise en ReactJS, prête à relever tous les défis. 
                Parlons-en autour d'un café – je suis définitivement team café !</span>
                <div className='btn button_space'>
                    <div className='button action'>
                        <Link to="/AboutMe" className='about_me'>About Me</Link>
                        </div>
                    <div className='button bg' onClick={handleScrollPr}>My projects</div>
                </div>
                <div className='subtitle'>Dev Front-End| UX </div>
            </div>
        </div>
    );
}

export default Header;