import { Link } from "react-router-dom";
import '../styles/error.css';

function Error404(){
return(
    <div className="error_page">
        <div className="error_space">
            <span className="error">Error 404</span>
            <span className="error_under">Page Erreur</span>
            <div className="error_link">
                <Link to="/">Home</Link>
                <Link to="/Contact">Contact</Link>
            </div>
        </div>
    </div>
)



}

export default Error404