import Header from "../components/Header";
import Projects from "../components/Projects";
import Card from "../components/Card";
import Footer from "../components/Footer"

function Home(){
return (
    <div>
        <Header/>
        <Projects/>
        <Card/>
        <Footer/>
    </div>
)
}

export default Home