import About from "../components/About";
import Footer from "../components/Footer.jsx";

function AboutMe(){

    return(
                <div>
                    <About/>
                    <Footer/>
                </div>
    )

}

export default AboutMe