import data from '../data.json';
import "../styles/project.css";
import Modale from './Modale.jsx';
import { useState} from 'react';

export function Card(){

    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleClick = (item) => {
        setSelectedItem(item);
        setShowModal(true); 
    };

    const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    };

    return(
        <div className='bg_card'>
            <div className='card_space'>
                {data.map((item,id) => {
                   
                return( 
                    <div key={item.id} className='img_card prj'>
                        <img src={item.images} 
                        alt="img" 
                        className="image" 
                        onClick={() => handleClick(item)}
                        />
                        <div className='card_unit'>
                            <div className='card_txt'>{item.description}</div>
                        </div>
                </div>)
                })}
             {showModal && <Modale item={selectedItem} closeModal={closeModal}/>}
             </div>
        </div>
    )
}

export default Card 